<template>
    <div>
        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>
        <b-row class="match-height">
            <b-col lg="12" md="12">
        
                <b-form @submit.prevent="formSubmit">
                    <b-card>

                        <b-row v-if="checkPermission('custom-forms', 'Update Item')">
                          <b-col md=12>

                            <div class="demo-inline-spacing float-right ">
                              
                                <b-button
                                  variant="danger"
                                  class="mt-0 mb-1 single_top_two_btn"
                                  :to="{ name: 'equipments'}"
                                >
                                <feather-icon
                                    icon="TargetIcon"
                                    class="mr-25"
                                  />
                                  <span>Equipments</span>
                                </b-badge>
                              </b-button>

                              <b-button
                                  variant="success"
                                  class="mt-0 mb-1"
                                  :to="{ name: 'work-essentials'}"
                                >
                                <feather-icon
                                    icon="PocketIcon"
                                    class="mr-25"
                                  />
                                  <span>Work Essentials</span>
                                </b-badge>
                              </b-button>

                            </div>
                          </b-col>
                        </b-row>

                        <b-alert v-model="showDismissibleAlert" variant="danger" dismissible class="mb-1">
                            <div class="alert-body">
                                {{error_message}}
                            </div>
                        </b-alert>

                        

                        <b-row>

                          <b-col md="12">
                              
                              <h4 class="card-title float-left"> Add Monthly Inspection Checklist</h4>
                              
                          </b-col>
                          
                        </b-row>
                    
                        <b-row>
                            <b-col md="12">
                                <b-row>

                                    <b-col md="6">
                                        <b-form-group label="Project Site" class="required">
                                            <b-form-select @change="draftCheck" v-model="form.site">
                                                <b-form-select-option value="" disabled>Select</b-form-select-option>
                                                <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                                            </b-form-select>
                                        </b-form-group>
                                    </b-col>

                                    <b-col md="6">
                                        <b-form-group label="Inspected By" class="required">
                                            <b-form-input autocomplete="off" placeholder="3 to 50 characters" v-model="form.inspected_by"/>
                                        </b-form-group>
                                    </b-col>
                                    
                                </b-row>

                            </b-col>
                        </b-row>

                    </b-card>

                    <b-card>

                        <div class="micTable table-responsive" style="margin-bottom: 0px !important;" v-if="form.equipments.length > 0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 4%;" class="text-center">#</th>
                                        <th scope="col" style="width: 20%;">Equipments</th>
                                        <th scope="col" style="width: 14%;" class="text-center">Satisfactory</th>
                                        <th scope="col" style="width: 19%;" class="text-center">Need Some Attention</th>
                                        <th scope="col" style="width: 24%;" class="text-center">Need Immediate Attention</th>
                                        <th scope="col" style="width: 19%">Comment</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(equip, index) in form.equipments" :key="equip._id" v-if="form.equipments.length > 0">
                                        <td class="text-center"> {{index + 1}} </td>
                                        <td>
                                            {{equip.equipment_name | capitalize}}
                                        </td>
                                        <td class="text-center chk_box_center" v-for="(accept, idx) in equip.acceptance" :key="idx">
                                            <b-form-checkbox class="text-center" @change="equipmentCheckbox($event, accept, index)" v-model="accept.status" />
                                        </td>
                                        <td>
                                            <b-form-input autocomplete="off" placeholder="Comment..." v-model="equip.comment"/>
                                        </td>
                                    </tr>
                                    <tr v-if="form.equipments.length == 0">
                                        <td colspan="6" class="text-center">No Record Found</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else> <h5 class="text-center">No Equipment Found.</h5></div>

                        <!-- <b-row class="mb-1">
                            <b-col md="1">
                                <h5> # </h5>
                            </b-col>

                            <b-col md="3">
                                <h5> Equipments </h5>
                            </b-col>

                            <b-col md="2">
                                <h5> Satisfactory </h5>
                            </b-col>

                            <b-col md="2">
                                <h5> Need Some Attention </h5>
                            </b-col>

                            <b-col md="2">
                                <h5> Need Immediate Attention </h5>
                            </b-col>

                            <b-col md="2">
                                <h5> Comment </h5>
                            </b-col>
                        </b-row>
                        <hr>
                        <div v-for="(equip, index) in form.equipments" :key="equip._id" v-if="form.equipments.length > 0">
                            <b-row class="mb-0">
                                <b-col md="1" class="mb-1"> {{index + 1}} </b-col>
                                <b-col md="3" class="mb-1"> {{equip.equipment_name | capitalize}} </b-col>
                                
                                <b-col md="2" class="mb-1" v-for="(accept, idx) in equip.acceptance" :key="idx"> 
                                    <b-form-checkbox @change="equipmentCheckbox($event, accept, index)" v-model="accept.status"></b-form-checkbox>
                                </b-col>

                                <b-col md="2" class="mb-1">
                                    <b-form-group class="required">
                                        <b-form-input autocomplete="off" placeholder="Comment..." v-model="equip.comment"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <hr>
                        </div>
                        <div v-if="form.equipments.length == 0">
                            <b-row class="mb-0">
                                <b-col md="12" class="text-center"> No Record Found </b-col>
                                
                            </b-row>
                            <hr/>
                        </div> -->
                    </b-card>

                    <b-card>

                        <div class="fmlTable table-responsive" style="margin-bottom: 0px !important;" v-if="form.crew.length > 0">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th scope="col" style="width: 4%;" class="text-center">#</th>
                                        <th scope="col" style="width: 72%;">Crew Name</th>
                                        <th scope="col" class="text-center" v-for="(essential, idx) in essentials" :key="idx">
                                            {{ essential.essential_name }}
                                        </th>
                                        <th scope="col" style="width: 20%;" class="text-center">Satisfactory</th>
                                        <th scope="col" style="width: 20%;" class="text-center">Need Replacement</th>
                                        <th scope="col" style="width: 4%"></th>
                                    </tr>
                                </thead>
                                <tbody v-for="(cr, index) in form.crew" :key="index">
                                    <tr>
                                        <td class="text-center"> {{index + 1}} </td>
                                        <td>
                                            <b-form-input autocomplete="off" placeholder="Crew Name" v-model="cr.full_name"/>
                                        </td>
                                        <td class="text-center chk_box_center" v-for="(esse, idxx) in cr.essentials" :key="idxx">
                                            <b-form-checkbox class="mt-1" v-model="esse.status" @change="crewCheckbox($event, index);" />
                                        </td>
                                        <td class="text-center chk_box_center">
                                            <b-form-checkbox class="mt-1" v-model="cr.satisfactory" disabled />
                                        </td>
                                        <td class="text-center chk_box_center">
                                            <b-form-checkbox class="mt-1" v-model="cr.need_replacement" disabled />
                                        </td>
                                        <td>
                                            <b-button variant = "danger" @click="removeCrew(index);">-</b-button>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td :colspan="cr.essentials.length + 2">
                                            <b-form-textarea rows="2" placeholder="Comment..." v-model="cr.comment"/>
                                        </td>
                                        <td colspan="2" v-if="cr.need_replacement == true">
                                            <CrewSignature v-bind:index="index" v-bind:crewSign="cr.crew_sign"/>
                                        </td> 
                                        <td colspan="2" v-else></td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div v-else> <h5 class="text-center">No Crew Found.</h5></div>
                        <!-- 
                        <b-row class="mb-1 mt-1">
                            <b-col md="1">
                                <h5> # </h5>
                            </b-col>

                            <b-col md="3">
                                <h5> Crew Name </h5>
                            </b-col>

                            <b-col md="1" v-for="(essential, idx) in essentials" :key="idx">
                                <h5> {{essential.essential_name}} </h5>
                            </b-col>

                            <b-col md="1">
                                <h5> Satisfactory </h5>
                            </b-col>

                            <b-col md="1">
                                <h5> Need Replacement </h5>
                            </b-col>

                            
                            <b-col md="1" class="text-center">
                                
                            </b-col>
                        </b-row>
                        <hr>
                        
                        <div v-for="(cr, index) in form.crew" :key="index">
                            <b-row class="mb-0">
                                <b-col md="1" class="mb-1"> {{index + 1}} </b-col>
                                <b-col md="3" class="mb-1"> 
                                    
                                    <b-form-input autocomplete="off" placeholder="Crew Name" v-model="cr.full_name"/> <br>


                                </b-col>
                                <b-col md="1" class="mb-1" v-for="(esse, idxx) in cr.essentials" :key="idxx"> 
                                    <b-form-checkbox v-model="esse.status" @change="crewCheckbox($event, index);"></b-form-checkbox>
                                </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <b-form-checkbox v-model="cr.satisfactory" disabled></b-form-checkbox>
                                </b-col>
                                <b-col md="1" class="mb-1"> 
                                    <b-form-checkbox v-model="cr.need_replacement" disabled></b-form-checkbox>
                                </b-col>
                                <b-col md="1" class="text-center">
                                    
                                    <b-button variant = "danger" @click="removeCrew(index);" class="one_rem_bottom_mobile">-</b-button>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col offset-md="1" :md="cr.need_replacement == true ? 8 : 10">
                                    <b-form-group class="required">
                                        <b-form-textarea rows="2" placeholder="Comment..." v-model="cr.comment"/>
                                    </b-form-group>
                                </b-col>
                                <b-col md="2" class="text-center" v-if="cr.need_replacement == true">
                                    <CrewSignature v-bind:index="index" v-bind:crewSign="cr.crew_sign"/>
                                </b-col>
                            </b-row>
                            <hr v-if="(form.crew.length - 1) != index">
                        </div> -->
                        <b-row v-if="form.site.length > 0">
                            <b-col md="12" class="text-center mt-1">
                                <b-button class="" variant="primary" @click="addCrew">Add More</b-button>
                            </b-col>
                        </b-row>
                        
                    </b-card>
                    
                    <b-card>

                        <b-row>
                            <!--<b-col cols="5" class="mb-2 mt-2"> </b-col>-->
                            <b-col cols="12" class="mb-2 mt-2 margin_bottom_zero_mobile"> 

                                
                                    
                                        <center>
                                            <h4 class="mb-0 pb-2 align_start_mobile"> Signature <span style="color:red">*</span></h4>
                                        </center>
                                   
                                

                                    <center class="align_start_mobile full_height_width_img_mobile">
                                        <b-media class="mb-2  margin_top_zero_mobile desktop_display_block" @click="openSignature('supervisor')" v-if="supervisorSign.image == null">
                                            
                                            <template #aside>
                                                <b-avatar ref="supervisorS" :src="doc_icon" variant="light-info" size="200px" rounded />
                                            </template>

                                            <div class="d-flex flex-wrap">
                                                <input ref="supervisorSInput" type="file" class="d-none" @input="supervisorImageUpload" >
                                            </div>
                                        
                                        </b-media>
                                        
                                        <img :src="supervisorSign.image" width="200px" height="180" v-if="supervisorSign.image != null" style="background: #ffffff">
                                    </center>
                                        <center>
                                            <b-button variant="danger" class="mt-1 ml-2 btn_danger_mobile_left_mrgn_zero" v-if="supervisorSign.image != null" @click="removeSign('supervisor')">
                                                <feather-icon icon="XIcon" />
                                            </b-button>
                                        </center>
                                    
                                    

                            </b-col>
                        </b-row>
                    
                        <b-row>
                            <b-col class="daily_report_sign">

                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="goBack()" class="mr-1">
                                    Cancel
                                </b-button>

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" variant = "danger" class = "mr-1" v-if="id != null" @click="discardItem(id)">
                                    Discard Draft
                                </b-button>
                          

                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "primary" class = "mr-1" @click="status = 'draft'" :disabled="form.site == ''">
                                    {{ id == null ? 'Save as Draft' : 'Update Draft'}}
                                </b-button>


                                <b-button v-ripple.400 = "'rgba(255, 255, 255, 0.15)'" type = "submit" variant = "warning" class = "mr-1" @click="status = 'completed'">
                                    Submit
                                </b-button>

                                
                            </b-col>
                        </b-row>
                    </b-card>

                </b-form>

            </b-col>
        </b-row>

        <b-modal
        id="signatureModel"
        ref="signatureModel"
        centered
        title="Signature"
        no-close-on-backdrop
        hide-footer
        @hide="hideSignature"
        @show="hideSignature"
      >
      
      <div v-if="show_default == true">
        <b-row>

          <b-col md="11" style="background: #ffffff; height:300px; vertical-align: middle;margin-left: 4%;margin-right:2%">
            <center>
              <img :src="tempSign.image" width="240px" height="200px" style="background: #ffffff; border-radius: 4px;margin-top: 11%">
            </center>

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="6">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  @click="show_default = false"
                >
                <span class="text-nowrap">Add New Signature</span>
              </b-button>
            </b-col>

            <b-col md="6">
              <b-button
                  variant="warning"
                  class="mt-0 float-right w-100"
                  @click="saveDefault()"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>

      <div v-else>
        <b-row>
          <b-col md="12">
            <VueSignaturePad
              id="signature"
              width="100%"
              height="300px"
              ref="signaturePad"
              :options="{onBegin: () => {$refs.signaturePad.resizeCanvas()},penColor: '#000000',minWidth: 3,maxWidth: 3}"
            />

          </b-col>
        </b-row>
        <b-row class="mt-2">

          <b-col md="3">
            <b-button
                variant="primary"
                class="mt-0 w-100"
                @click="uploadSign()"
              >
              <span class="text-nowrap">Upload</span>
            </b-button>
          </b-col>

          <b-col md="3">

              <b-button
                  variant="secondary"
                  class="mt-0 w-100"
                  v-if="having_default == true"
                  @click="show_default = true"
                >
                <span class="text-nowrap">Default</span>
              </b-button>
            </b-col>


          <b-col md="3">

              <b-button
                  variant="danger"
                  class="mt-0 w-100"
                  @click="clearSignature"
                >
                <span class="text-nowrap">Clear</span>
              </b-button>
            </b-col>

            <b-col md="3">
              <b-button
                  variant="warning"
                  class="mt-0 w-100"
                  @click="saveSignature"
                >
                <span class="text-nowrap">Submit</span>
              </b-button>
            </b-col>
        </b-row>
      </div>
      </b-modal>

    </div>
</template>
<script>
    import {
        BFormTextarea, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import vSelect from 'vue-select';
    import CrewSignature from './CrewSignature.vue';
    import Bus from "../../../event-bus";

    export default {
        components: {
            CrewSignature, BFormTextarea, BAlert, BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormSelect, BFormSelectOption, BFormText, BInputGroupPrepend, BInputGroup, BMedia, BAvatar, BTable, BModal, VueCropper, BFormRadio, BFormTimepicker, VueTimepicker, vSelect, BFormFile,BBreadcrumb
        },

        directives: {
            Ripple,
        },

        data() {
            return {        
                error_message:null,
                showDismissibleAlert:false,
                
                sites : null,
                essentials : [],
                id:null,
                status:'completed',
                form :{
                    site : '',/*this.$route.params.site_id*/
                    inspected_by : this.$store.getters.currentUser.full_name,
                    equipments: [],
                    crew: [],
                },

                // signature vars
                doc_icon: require('@/assets/images/doc.png'),
                supervisorSign : {
                    image:null,
                    name:'',
                    type: 'supervisor',
                    default:'no'
                },
                openedSignModel:null,
                tempSign : {
                    image:null,
                    name:'',
                },
                show_default:false,
                having_default:false,
            }
        },

        methods: {
            formSubmit(){

                if (this.status == 'completed') {
                    this.decisionAlert('Are you sure want to submit this report ?')
                    .then(result => {
                      if (result.value) {
                        this.submitFunc();            
                      }
                    })
                }else{
                    this.submitFunc();
                }
                
            },
            submitFunc(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        items : this.form,
                        supervisorSign : this.supervisorSign,
                        id:this.id,
                        status:this.status
                    },
                    api : '/api/add-monthly-inspection-checklist'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message        = this.$store.getters.getErrors;
                        //this.showDismissibleAlert = true;

                        //window.scrollTo(0,0);
                        this.errorAlertCF()
                    } else {
                        this.showDismissibleAlert = false;

                        var data = this.$store.getters.getResults.data;

                        this.successAlert().then((result) => {

                            this.form = {
                                site : '',/*this.$route.params.site_id,*/
                                inspected_by : this.$store.getters.currentUser.full_name,
                                equipments: [],
                                crew: [],
                            };
                            this.supervisorSign = {
                                image:null,
                                name:'',
                                type: 'supervisor',
                                default:'no'
                            };
                            this.openedSignModel=null;
                            this.id = null;

                            if (this.sites.length == 1) {
                                this.form.site = this.sites[0]._id;
                                this.draftCheck();
                            }
                            //this.allEquipments();
                            //console.log(this.form);

                            //this.draftCheck();
                            
                            if (data != null) {
                                window.open(data, '_blank');
                            }
                            localStorage.setItem('routeCFCheck','no');
                            // this.$router.go(-1);
                            this.$router.push({ name:'custom-forms' })
                        });
                    }
                });
            },

            allEquipments(){
                return this.$store.dispatch(GET_API, {
                    data:{},
                    api: '/api/all-equipments'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data = this.$store.getters.getResults.data;
                        
                        data.forEach(ele => {
                            var temp = {};

                            temp._id = ele._id;
                            temp.equipment_name = ele.equipment_name;
                            temp.comment = null;
                            temp.acceptance = [
                                {label: 'Satisfactory', status: true},
                                {label: 'Need Some Attention', status: false},
                                {label: 'Need Immediate Attention', status: false}
                            ];

                            this.form.equipments.push(temp);
                        });
                    }
                });
            },

            allEssentials(){
                return this.$store.dispatch(GET_API, {
                    data:{},
                    api: '/api/all-essentials'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        data.forEach(ele => {
                            var temp = {};
                            temp._id = ele._id;
                            temp.essential_name = ele.essential_name;
                            temp.status = false;

                            this.essentials.push(temp);
                        });
                    }
                });
            },
            
            allSites(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        role:this.$store.getters.currentUser.role,
                        om_sites:this.$store.getters.currentUser.om_sites,
                    },
                    api: '/api/all-sites'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        this.sites = data;

                        if (this.sites.length == 1) {
                            this.form.site = this.sites[0]._id;
                            this.draftCheck();
                        }

                        return this.sites;
                    }
                });
            },

            equipmentCheckbox(e, data, index){
                if(e){
                    this.form.equipments[index].acceptance.forEach(ele => {
                        if(ele.label != data.label){
                            ele.status = false
                        }
                    });
                }
            },
            
            crewCheckbox(e, index){
                var essentialCount = this.form.crew[index].essentials.length;
                var trueCount = 0;
                var falseCount = 0;

                this.form.crew[index].essentials.forEach(ele => {
                    if(ele.status == true){
                        trueCount++
                    }
                    if(ele.status == false){
                        falseCount++
                    }
                });
                if(essentialCount == trueCount){
                    this.form.crew[index].satisfactory = true;
                    this.form.crew[index].need_replacement = false;
                } else {
                    this.form.crew[index].satisfactory = false;
                    this.form.crew[index].need_replacement = true;
                }

                if(essentialCount == falseCount){
                    this.form.crew[index].satisfactory = true;
                    this.form.crew[index].need_replacement = false;
                }
            },

            draftCheck(){
                this.form.site != '' ? localStorage.setItem('routeCFCheck','yes') : localStorage.setItem('routeCFCheck','no');
                this.form.crew = [];
                this.form.equipments = [];
                
                //console.log(this.form.site);

                if (this.form.site != '') {
                    
                    return this.$store.dispatch(POST_API, {
                        data:{
                            site:this.form.site
                        },
                        api: '/api/draft-monthly-inspection'
                    })
                    .then(() => {
                        if (this.$store.getters.containsErrors) {
                            this.error_message = this.$store.getters.getErrors;
                            this.showDismissibleAlert = true;
                            window.scrollTo(0,0);
                        } else {
                            this.showDismissibleAlert = false;
                            var data  = this.$store.getters.getResults.data;
                            
                            if (data == null) {

                                this.id = null;
                                this.allEquipments();
                                this.siteCrew();
                                
                                this.supervisorSign = {
                                    image:this.tempSign.image,
                                    name:'',
                                    type: 'supervisor',
                                    default: this.tempSign.image != null ? 'yes' : 'no'
                                };

                            }else{
                                this.id = data._id;
                                this.form.crew = [];
                                this.form.equipments = data.equipments;
                                this.form.inspected_by =  data.inspected_by;
                                
                                this.supervisorSign = {
                                    image:data.signature,
                                    name:'',
                                    type: 'supervisor',
                                    default:'yes'
                                };

                                data.crew.forEach(user => {
                                    var tempUser = {};
                                    tempUser._id = user._id;
                                    tempUser.full_name = user.full_name;
                                    tempUser.essentials = user.essentials;
                                    tempUser.satisfactory = user.satisfactory;
                                    tempUser.need_replacement = user.need_replacement;
                                    tempUser.comment = user.comment;
                                    
                                    tempUser.crew_sign = {
                                        default:user.signature != null ? 'yes' : 'no',
                                        name:'',
                                        image:user.signature,
                                    };

                                    this.form.crew.push(tempUser);
                                });

                                var temp = JSON.stringify(this.form.crew);

                                this.form.crew = JSON.parse(temp);
                            }
                        }
                    });
                } else {
                    this.supervisorSign = {
                        image:this.tempSign.image,
                        name:'',
                        type: 'supervisor',
                        default:'yes'
                    };
                }
            },

            siteCrew(){
                return this.$store.dispatch(POST_API, {
                    data:{
                        site: this.form.site,
                    },
                    api: '/api/all-crew'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                    } else {
                        this.form.crew = [];
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        data.forEach(user => {
                            var tempUser = {};
                            tempUser._id = user._id;
                            tempUser.full_name = user.full_name;
                            tempUser.essentials = this.essentials;
                            tempUser.satisfactory = true;
                            tempUser.need_replacement = false;
                            tempUser.comment = null;
                            tempUser.crew_sign = {
                                default:'no',
                                name:'',
                                image:null,
                            };

                            this.form.crew.push(tempUser);
                        });

                        var temp = JSON.stringify(this.form.crew);

                        this.form.crew = JSON.parse(temp);
                    }
                });
            },

            addCrew(){
                var temp = JSON.stringify({
                    _id: null,
                    full_name: '',
                    comment: '',
                    essentials: this.essentials,
                    satisfactory : true,
                    need_replacement : false,
                    crew_sign: {
                        default:'no',
                        name:'',
                        image:null,
                    }
                });

                this.form.crew.push(JSON.parse(temp))
            },

            removeCrew(index) {
                if(this.form.crew.length > 1){
                    this.form.crew.splice(index, 1);
                }
            },

            supervisorImageUpload(event){
                var input = event.target;
                var files = event.target.files

                if (input.files && input.files[0]) {
                    var reader = new FileReader();
                    reader.readAsDataURL(input.files[0]);
                    var image = input.files[0];

                    if (image.type != 'image/png' && image.type != 'image/jpeg' && image.type != 'image/jpg') {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Please upload .png, .jpeg, .jpg images only',
                            showConfirmButton: false,
                            timer: 1500
                        });

                        this.$refs.supervisorSInput.value=null;

                    } else if (image.size > 2097152) {
                        Swal.fire({
                            position: 'center',
                            icon: 'warning',
                            title: 'Maximum 2 MB files allowed to be upload.',
                            showConfirmButton: false,
                            timer: 1500
                        })
                        this.$refs.supervisorSInput.value=null;
                    } else {
                        reader.onload = (e) => {
                            this.supervisorSign.image = e.target.result;
                            this.supervisorSign.name = image.name;
                            this.supervisorSign.type =  'supervisor';
                            this.supervisorSign.default = 'no';
                        }
                    }
                }
            },
                
            removeSign(sign){
                this.supervisorSign.image = null;
                this.supervisorSign.name = '';
                this.supervisorSign.default = 'no';
            },
            discardItem(id){
                var msg = 'Are you sure want to discard this record?';

                this.decisionAlert(msg)
                .then(result => {
                    if (result.value) {
                        return this.$store.dispatch(POST_API, {
                            data:{
                                id : id,
                                
                            },
                            api : "/api/discard-mi",
                        })
                        .then(() => {
                            if (this.$store.getters.containsErrors) {
                                this.error_message = this.$store.getters.getErrors;

                                this.showAlert();
                            } else {
                                /*this.successAlert()
                                .then(() => {
                                    this.form = {
                                        site : '',
                                        inspected_by : this.$store.getters.currentUser.full_name,
                                        equipments: [],
                                        crew: [],
                                    };
                                    this.supervisorSign = {
                                        image:null,
                                        name:'',
                                        type: 'supervisor',
                                        default:'no'
                                    };
                                    this.openedSignModel=null;
                                    this.id = null;
                                });*/
                                localStorage.setItem('routeCFCheck','no');
                                this.successAlert()
                                .then(() => {
                                    
                                    // this.$router.go(-1);
                                    this.$router.push({ name:'custom-forms' })
                                });
                            }
                        });
                    }
                })
                .catch(err => {
                    console.log(err);
                })
            },
            useDefault(){

              return this.$store.dispatch(POST_API, {
                   data:{
                     id:this.$store.getters.currentUser._id
                   },
                   api: '/api/get-default-signature'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                        this.showDismissibleAlert = true;
                        window.scrollTo(0,0);
                    } else {
                        this.showDismissibleAlert = false;
                        var data  = this.$store.getters.getResults.data;
                        
                        if (data == null || data.default_signature == null) {
                          
                          this.having_default = false;
                          
                        }else{

                          this.tempSign = {
                            image : data.default_signature,
                            name : 'siganture.png',
                          }

                          this.having_default = true;
                        }
                    }
                });
            },
            uploadSign(){
              
                this.$refs['supervisorSInput'].click();
              
                this.$refs['signatureModel'].hide();

            },
            openSignature(type){
              if (this.openedSignModel == null) {

                  if (this.having_default == true) {
                      this.show_default = true;
                  }else{
                    this.show_default = false;
                  }

                  this.$refs['signatureModel'].show();
                  this.openedSignModel = type;
              }
            },
            hideSignature(){
              this.openedSignModel = null;
            },
            clearSignature(){
              this.$refs.signaturePad.clearSignature();
            },
            saveSignature(){
              const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
              
              if (isEmpty) {

                Swal.fire({
                  position: 'center',
                  icon: 'warning',
                  title: 'Signature is required',
                  showConfirmButton: false,
                  timer: 1500
                })

              }else{


                this.supervisorSign.image = data;
                this.supervisorSign.name = 'siganture.png';
                this.supervisorSign.type = 'supervisor';
                this.supervisorSign.default = 'no';

                this.$refs['signatureModel'].hide();
              }
            },
            saveDefault(){
              
              
                this.supervisorSign = {
                  image : this.tempSign.image,
                  name : 'signature.png',
                  type :   'supervisor',
                  default:'yes'
                }
                this.$refs['signatureModel'].hide();
            },
            breadCrumb(){
              var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
              },{
                to:{name:'custom-forms'},
                text: 'Custom Forms',
              },{
                to:null,
                text:'Add Monthly Inspection Checklist',
                active:true
              }];
              return item;
            },
            goBack(){

                if (this.form.site != '') {
                    var msg = 'Do you want to Go back without saving your report?';

                    this.decisionAlert(msg)
                    .then(result => {
                      if (result.value) {
                        
                        // this.$router.go(-1);
                        this.$router.push({ name:'custom-forms' })

                      }
                    })
                }else{
                    // this.$router.go(-1);    
                    this.$router.push({ name:'custom-forms' })                
                }
                
            }
        },
        mounted(){
            this.useDefault();
            this.allSites().then(() => {
                this.allEssentials()
                this.draftCheck();
                /*this.allEquipments().then(() => {
                });*/
            });

            // to add crew sign
            Bus.$on('update-image', (data) => {
                this.form.crew[data.index].crew_sign = data.data;
            })

            // to delete crew sign
            Bus.$on('remove-image', (data) => {
                this.form.crew[data].crew_sign = {
                    default:'no',
                    name:'',
                    image:null,
                };
            })
        }
    }
</script>
